<template>
  <v-app :style="colorVapp">
    <onebox_toolbar></onebox_toolbar>
    <v-content>
      <v-overlay :value="processloader" z-index="2" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay>
      <v-list nav :color="color.BG" class="px-4" v-if="resolutionScreen < 500">
        <v-card rounded="xl" class="elevation-0 d-flex align-center" height="40">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center ma-0">
              <v-avatar :color="color.theme" size="25">
                <v-icon dark size="20" @click="fn_goto_mypackage()">mdi-keyboard-backspace</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="pl-3 pt-1 pb-1">
                <span :style="headerPage">{{ $t("packagenew.purchase_package") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <br>
        <v-layout>
          <v-flex xs6>
            <v-btn :color="color.theme" dark block @click="fn_goto_mypackage()">
              {{ $t("packagenew.my_package") }}
            </v-btn>
          </v-flex>
          <v-flex xs6 class="ml-4">
            <v-btn :color="color.theme" dark block class="pa-0" @click="fn_goto_packagehistory()">
              <!-- {{ $t("packagenew.package_history") }} -->
              <span class="text-wrap" style="width: 95%;">{{ $t("packagenew.package_history") }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-list>
      <v-list nav :color="color.BG" v-else>
        <v-list-item class="text-left">
          <v-list-item-avatar class="text-center">
            <v-avatar :color="color.theme" size="35">
              <v-icon dark size="20" @click="fn_goto_mypackage()">mdi-chevron-left</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="pl-3 pt-1 pb-1">
              <span :style="headerPage">{{ $t("packagenew.purchase_package") }}</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-flex>
              <v-btn :color="color.theme" dark small class="mr-2" @click="fn_goto_mypackage()">
                {{ $t("packagenew.my_package") }}
              </v-btn>
              <v-btn :color="color.theme" dark small @click="fn_goto_packagehistory()">
                {{ $t("packagenew.package_history") }}
              </v-btn>
            </v-flex>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-card v-if="resolutionScreen >= 400" class="elevation-0" :color="color.BG" id="thiscontainer_package">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center v-if="loaddataprogress === true">
              <v-flex lg12 class="ma-2 ml-4 mr-4" >
                <div class="text-center">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-center v-else>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <v-card class="mx-auto" outlined id="borderradius">
                  <v-layout row wrap justify-center>
                    <v-flex xs9 lg9>
                      <v-list-item class="ml-4">
                        <v-list-item-content>
                          <v-layout row wrap justify-center>
                            <v-flex lg12 xs12>
                              <v-row>
                                <v-col class="ml-4 mt-6" cols="6">
                                  <h3 class="text-left mt-2">
                                    {{ $t("packagenew.current_mystorage") }} {{ total_storage_business }}
                                  </h3>
                                </v-col>
                                <v-col cols="4" class="mt-4">
                                  <v-progress-linear
                                    :color="color_storage"
                                    height="10"
                                    rounded
                                    :value="percentstorage"
                                  ></v-progress-linear
                                  ><br />
                                  <h4 class="ma-2 ml-2">
                                    {{ $t("packagenew.used_space") }} {{ use_storage_business }}/{{ total_storage_business }}
                                  </h4>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                    <v-flex xs3 lg3>
                      <v-img :src="require('@/assets/img/package2.png')" max-width="120" max-height="120" class="mt-4 mb-4 ml-6" />
                    </v-flex>
                  </v-layout>
                </v-card>
                <br />
                <v-divider></v-divider>
                <h2 class="text-center mt-10 mb-1" :style="darkModeText">
                  {{ $t("packagenew.title_package") }}
                </h2>
                <br />
                <v-row class="text-center">
                  <v-col cols="12" class="py-2">
                    <v-btn-toggle
                      v-model="packageTypeSelected"
                      :color="packageTypeSelected ? '#2D4E9A' : 'grey lighten-1'"
                      borderless
                      mandatory
                      @change="getdatapackage(packageTypeSelected)"
                    >
                      <v-btn value="M">
                        <span>{{ $t("packagenew.month") }}</span>
                      </v-btn>
                      <v-btn value="Y">
                        <span>{{ $t("packagenew.year") }}</span>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-flex>
              <br />

              <!-- package -->
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <v-sheet class="mx-auto mr-4 ml-4" elevation="0" id="borderradius_card">
                  <v-slide-group v-model="model" class="pa-4" center-active show-arrows>
                    <v-slide-item
                      active-class="primary"
                      v-for="(item, text) in listpackage"
                      :key="text"
                      class="ma-1"
                      v-slot="{ active, toggle }"
                    >
                      <v-card
                        height="570"
                        width="300"
                        outlined
                        style="height: 570px;"
                        hover
                        :color="active ? 'primary' : 'lighten-1'"
                        :dark="active ? true : false"
                      >
                        <v-layout justify-left class="pt-2">
                          <v-card-title :style="active ? price_dark : $vuetify.theme.dark ? price_dark : price_">{{ numberWithCommas(item.package_price) }}</v-card-title>
                          <p class="text-center mt-6" style="fornt-size:25" v-if="packageTypeSelected === 'M'">{{ $t("packagenew.baht_month") }}</p>
                          <p class="text-center mt-6" style="fornt-size:25" v-else>{{ $t("packagenew.baht_year") }}</p>
                        </v-layout>
                        <v-layout justify-left>
                          <h2 class="text-center mt-4 ml-4 " style="fornt-size:24">{{ item.package_name }}</h2>
                        </v-layout>
                        <v-layout justify-left>
                          <h2
                            class="text-center mt-4 ml-4 "
                            :style="active ? 'fornt-size:24; color: #FFFFFF;' : 'fornt-size:24; color: #1279BC; '"
                          >
                            {{ item.package_storage }} 
                          </h2>
                        </v-layout>
                        <br />
                        <v-layout justify-left>
                          <v-card class="mx-auto" max-width="344" elevation="0">
                            <v-list :color="active ? '#1279bc' : $vuetify.theme.dark ? '#363636' : '#FAFAFA'" class="#FFFFFF" :dark="active ? true : false">
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Manage User</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Upload/Download/Share</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>accessibility</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Restrict Access</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>find_in_page</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Advance Search</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Connect Through API</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title> Send File to e-Mail</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-layout>
                        <br />
                        <v-flex lg12 xs12 class="mr-4 ml-4">
                        </v-flex>
                        <v-flex lg12 xs12 class="mr-4 ml-4 ma-4">
                          <div class="text-center" @click="toggle">
                            <v-btn rounded color="#5AB685" dark @click="fn_selectpackage(active, item, 1)">
                            <!-- {{active === true ? 'แพ็กเกจปัจจุบัน' : 'เลือกแพ็กเกจ'}}   -->{{ $t("packagenew.choose_package") }}
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
               
                </v-sheet>
              </v-flex>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <p class="font-weight-regular mt-2" style="color:red;">
                  {{ $t("packagenew.vat") }}
                </p>
                <br />
                <v-divider></v-divider>
              </v-flex>

              <v-layout>
              <!-- package  addon-->
              <v-flex lg6 xs6 class="mr-4 ml-4">
                <h2 class="text-left mt-10 mb-1" :style="darkModeText">
                  {{ $t("addon_package.addon_package") }}
                </h2>
                <p class="text-left font-weight-regular mr-2 mt-2" :style="$vuetify.theme.dark ? 'color: #bfbfbf' : 'color: #757575;'">
                  {{ $t("addon_package.addon_title") }}
                </p>
                <!-- ตาราง addon -->
                <div>
                  <v-data-table
                    v-model="filemultipleaddon"
                    :headers="headers"
                    :items="listpackage_addon"
                    :no-data-text="$t('tablefile.empty_resend')"
                    :single-select="false"
                    :search="search"
                    item-key="id"
                    item-selected
                    :show-select="statusmutipleaddon"
                    class="elevation-0"
                    :items-per-page="listpackage_addon.length"
                    :hide-default-footer="true"
                  >
                    <template v-slot:[`header.package_name`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.package_price`]="{ header }">
                      <span
                        class="pointer"
                        @click="(multiSort = !multiSort), (sortby = 'name'), (clickSort = true), sortFile(rootfile)"
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <template v-slot:[`header.package_num_package`]="{ header }">
                      <span
                        class="pointer"
                        @click="(multiSort = !multiSort), (sortby = 'owner'), (clickSort = true), sortFile(rootfile)"
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <template v-slot:item="props">
                      <tr @contextmenu="test($event, props.item)" style="cursor: pointer">
                        <td >{{ props.item.package_name }}</td>
                        <td class="text-center">{{ numberWithCommas(props.item.package_price) }}</td>
                        <td class="text-right">
                          <v-layout v-if="resolutionScreen >= 500" row wrap justify-center>
                            <v-flex xs4 lg4>
                              <v-btn
                                class="white--text elevation-0 mt-4"
                                fab
                                small
                                :color="color.theme"
                                @click="
                                  props.item.package_num_package -=
                                    1 | fn_minus_package(props.index, props.item, (props.item.package_num_package -= 1))
                                "
                                :disabled="
                                  listpackage_current_main.length == 0 && list_selected_package_main.length == 0 
                                    ? true
                                    : props.item.package_num_package == 0 
                                    ? true
                                    : false
                                "
                              >
                                <v-icon>mdi-minus</v-icon>
                              </v-btn>
                            </v-flex>
                            <v-flex xs4 lg4>
                              <v-row class="ml-1" align-content="center" justify-center>
                              <v-text-field
                                outlined
                                dense
                                class="ml-5 mr-5 mt-4 text-center"
                                v-model="props.item.package_num_package"
                                readonly
                              ></v-text-field>
                              </v-row>
                            </v-flex>
                            <v-flex xs4 lg4>
                              <v-btn
                                class="white--text elevation-0 mr-12 mt-4"
                                fab
                                small
                                :color="color.theme"
                                @click.prevent="
                                  props.item.package_num_package +=
                                    1 | fn_plus_package(props.index, props.item, (props.item.package_num_package += 1))
                                "
                                :disabled="listpackage_current_main.length == 0 && list_selected_package_main.length == 0 ? true  : false"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                          <v-layout v-else row wrap justify-center>
                            <v-flex xs4 lg4>
                              <v-btn
                                class="white--text elevation-0 mr-2 mt-4"
                                fab
                                small
                                :color="color.theme"
                                @click="
                                  props.item.package_num_package -=
                                    1 | fn_minus_package(props.index, props.item, (props.item.package_num_package -= 1))
                                "
                                :disabled="
                                  listpackage_current_main.length == 0 && list_selected_package_main.length == 0 
                                    ? true
                                    : props.item.package_num_package == 0 
                                    ? true
                                    : false
                                "
                              >
                                <v-icon>mdi-minus</v-icon>
                              </v-btn>
                            </v-flex>
                            <v-flex xs4 lg4>
                              <v-text-field
                                outlined
                                dense
                                class="ml-1 mr-1 mt-4 text-center"
                                v-model="props.item.package_num_package"
                                readonly
                                text-align: center
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs4 lg4>
                              <v-btn
                                class="white--text elevation-0 mr-10 mt-4"
                                fab
                                small
                                :color="color.theme"
                                @click.prevent="
                                  props.item.package_num_package +=
                                    1 | fn_plus_package(props.index, props.item, (props.item.package_num_package += 1))
                                "
                                :disabled="listpackage_current_main.length == 0 && list_selected_package_main.length == 0 ? true  : false"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
               <!-- <v-card class="mx-auto mt-8" outlined id="borderradius">
                <v-row
                  class="mb-4 ma-2"
                  justify="space-between"
                >
                  <v-col class="text-left">
                    <span
                      class="text-h2 font-weight-light"
                      v-text="sliderstorage_addon"
                    ></span>
                    <span class="subheading font-weight-light mr-1">GB</span>
                  </v-col>
                  <v-col class="text-right">
                    <span
                      class="text-h2 font-weight-light"
                      v-text="pricetotal_addon"
                    ></span>
                    <span class="subheading font-weight-light mr-1">บาท</span>
                  </v-col>
                </v-row>
                <v-row class="ma-2">
                   <v-col cols="12">
                    <v-slider
                      v-model="sliderstorage_addon"
                      class="pt-6"
                      thumb-label="always"
                      step="10"
                    ></v-slider>
                  </v-col>
                </v-row>
               </v-card> -->
              </v-flex>
          
                <!-- <v-divider></v-divider> -->

              <!-- สรุปคำสั่งซื้อ -->
              <v-flex lg6 xs6 class="mr-4 ml-4 mt-4">
                <h2 class="text-left mt-8" :style="darkModeText">
                  {{ $t("Order_summary.Order_summary") }}
                </h2>
                <v-card class="mx-auto mt-8" outlined id="borderradius">
                  <v-list three-line>
                    <template>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t("Order_summary.main_package") }}</v-list-item-title>
                          <v-list-item-subtitle v-if="list_selected_package_main.length === 0"
                            >{{ $t("Order_summary.not_choose") }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle v-else>{{
                            list_selected_package_main_show[0].namepackage +
                              " " +  " | " +  " " +  
                              list_selected_package_main_show[0].storage 
                           
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-title v-if="list_selected_package_main.length === 0" :style="darkModeText">
                            0 {{$t("Order_summary.baht")}} </v-list-item-title
                          >
                          <v-list-item-title v-else :style="darkModeText">
                            {{ numberWithCommas(list_selected_package_main_show[0].price) + " " + $t("Order_summary.baht") }}</v-list-item-title
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list three-line>
                    <template>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t("Order_summary.addon_package") }}</v-list-item-title>
                          <v-list-item-subtitle v-if="listpackage_addon.length == 0">{{$t("Order_summary.not_choose_addon")}}</v-list-item-subtitle>
                          <v-list-item-subtitle v-for="(item, id) in listpackage_addon" :key="id" >{{ item.package_num_package != 0 ? item.package_name + " * " + item.package_num_package + " , " : ""}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-title v-if="listpackage_addon.length == 0" :style="darkModeText">0 {{$t("Order_summary.baht")}}</v-list-item-title>
                          <v-list-item-title :style="darkModeText" v-else>{{numberWithCommas(pricetotal_addon) + " " + $t("Order_summary.baht")}}</v-list-item-title>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list three-line>
                    <template>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>VAT 7 %</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <!-- <v-list-item-title style="color:#000000; fornt-size:25;" v-if="list_selected_package_main.length === 0">
                            0บาท</v-list-item-title
                          > -->
                          <v-list-item-title :style="darkModeText" >{{ numberWithCommas(vat) + " " + $t("Order_summary.baht") }}</v-list-item-title>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list three-line>
                    <template>
                      <v-list-item style="color:#ECFAFF;">
                        <v-list-item-content>
                          <v-list-item-title style="line-height:26px" >{{ $t("Order_summary.total") }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <!-- <v-list-item-title style="color:#000000; fornt-size:25;" v-if="list_selected_package_main.length === 0">
                            0 บาท</v-list-item-title
                          > -->
                          <v-list-item-title class="pt-4" :style="darkModeText" >{{
                            numberWithCommas(pricetotal) + " " + $t("Order_summary.baht") 
                          }}</v-list-item-title>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>

                <!-- <div class="text-center">
                  <v-btn
                    class="text-decoration-underline ma-6"
                    :style="taxInvoice == true ? 'color:#5AB685;  fornt-size:30;' : 'color:#1279BC; fornt-size:30;'"
                    @click="fn_tax_invoice()"
                    text
                  >
                    {{
                      taxInvoice == true
                        ? "✔ กรอกใบกำกับภาษีเรียบร้อย สามารถคลิกที่นี่เพื่อแก้ไข"
                        : "คลิกที่นี่เพื่อกรอกข้อมูลใบกำกับภาษี"
                    }}
                  </v-btn>
                </div> -->

                <!-- <v-card class="mx-auto mt-8" outlined id="borderradius">
                  <v-list subheader three-line>
                    <v-list-item v-for="folder in folders" :key="folder.title">
                      <v-list-item-content>
                        <v-list-item-title v-text="folder.title" style="color:#000000; fornt-size:25;"></v-list-item-title>

                        <v-list-item-subtitle v-text="folder.subtitle"></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-title v-text="folder.price" style="color:#000000; fornt-size:25;">บาท</v-list-item-title>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="folder < folders.length - 1" :key="folder.title"></v-divider>
                  </v-list>
                </v-card> -->
              </v-flex>
               </v-layout>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <p class="text-left font-weight-regular mt-2" style="color:red;">
                  {{ $t("Order_summary.vat") }}
                </p>
                <br />
              </v-flex>
              <v-divider></v-divider>
              <!-- ที่อยู่ใบกำกับภาษี -->
              <v-divider v-if="taxInvoice === true"></v-divider>
              <v-flex lg12 xs12 class="mr-4 ml-4" v-if="taxInvoice === true">
                   <h2 class="text-left mt-8" :style="darkModeText">
                  {{ $t("taxinvoice_address.taxinvoice_address") }}
                </h2>
                <v-card class="mx-auto mt-8" outlined id="borderradius">
                     <div>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs12 lg12 class="pl-8 pt-4"><p>{{$t("taxinvoice_address.purchaser") + "     " + name_invoice_th}}</p></v-flex>
                      <!-- <v-flex xs10 lg10 class="pl-4 pt-4"><p > {{ tax_num  }} </p></v-flex> -->
                    </v-layout>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs12 lg12 class="pl-8 "><p>{{$t("taxinvoice_address.address")  + "     " +  $t("taxinvoice_address.address_detail")}}</p></v-flex>
                    </v-layout>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs12 lg12 class="pl-8 "><p>{{$t("taxinvoice_address.tax_identification") + "      " +  tax_num + "   " + branch_name }}</p></v-flex>
                    </v-layout>
                  </div>
           
                </v-card>
              </v-flex>
              
               <v-flex lg12 xs12 class="mr-4 ml-4">
                 <div class="text-center">
                  <v-btn
                    class="text-decoration-underline ma-6"
                    :style="taxInvoice == true ? 'color:#5AB685;  fornt-size:30;' : 'color:#1279BC; fornt-size:30;'"
                    @click="fn_tax_invoice()"
                    text
                  >
                    {{
                      taxInvoice == true
                        ? $t("taxinvoice_address.edit_tax")
                        : $t("taxinvoice_address.click_here")
                    }}
                  </v-btn>
                </div> 
               </v-flex>

              <v-flex lg12 xs12 class="mr-4 ml-4">
                <div class="text-center">
                  <v-btn class="ma-2" outlined :color="color.theme" @click="fn_clear()">
                    {{ $t("packagenew.clear") }}
                  </v-btn>
                  <v-btn
                    class="ma-2 white--text"
                    :color="color.theme"
                    :disabled="listpackage_current_main.length === 0"
                    @click="fn_updatepackage()"
                    v-if="$route.query.typepay === 1 || listpackage_current.length != 0"
                  >
                    {{ $t("packagenew.update_package") }}
                  </v-btn>
                  <v-btn
                    class="ma-2 white--text"
                    :color="color.theme"
                    :disabled="list_selected_package_main.length === 0"
                    @click="fn_checktax()"
                    v-else
                  >
                    <!-- @click="opendialogchecktaxinvioce = true" -->
                    {{ $t("packagenew.pay") }}
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card v-else class="elevation-0" :color="color.BG" id="thiscontainer_package">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center v-if="loaddataprogress === true">
              <v-flex lg12 class="ma-2 ml-4 mr-4" >
                <div class="text-center">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-center v-else>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <!-- พื้นที่จัดเก็บข้อมูลปัจจุบัน [new] -->
                <v-card class="py-4" outlined id="borderradius">
                  <v-list-item>
                    <v-icon :color="color.theme" class="pr-2">storage</v-icon>
                    <h3 :style="'color:' + color.theme">{{ $t("packagenew.current_mystorage") }}</h3>
                    <v-spacer></v-spacer>
                    <v-chip color="#E9A016">
                      <h3 class="white--text">{{ total_storage_business }}</h3>
                    </v-chip>
                  </v-list-item>
                  <v-card-text class="py-0">
                    <v-progress-linear
                      :color="color_storage"
                      height="7"
                      rounded
                      :value="percentstorage"
                    ></v-progress-linear>
                    <p class="text-left">{{ $t("packagenew.used_space") }} {{ use_storage_business }}/{{ total_storage_business }}</p>
                  </v-card-text>
                  <v-divider class="mx-4"></v-divider>
                  <v-list-item>
                    <v-icon :color="color.theme" class="pr-2">mdi-account-multiple</v-icon>
                    <h3 :style="'color:' + color.theme">{{ $t("packagenew.total_users") }}</h3>
                    <v-spacer></v-spacer>
                    <v-chip color="#52A9FF">
                      <h3 class="white--text">{{ use_user }} User</h3>
                    </v-chip>
                  </v-list-item>
                </v-card>
                <!-- พื้นที่จัดเก็บข้อมูลปัจจุบัน -->
                <!-- <v-card class="mx-auto" outlined id="borderradius">
                  <v-layout row wrap justify-center>
                    <v-flex xs12 lg12>
                      <v-list-item class="ml-5">
                        <v-list-item-content>
                          <v-layout row wrap justify-center>
                            <v-flex lg12 xs12>
                              <v-row justify-center>
                                <v-col class="ml-6" cols="12">
                                  <h3 class="text-left">
                                    {{ $t("packagenew.current_mystorage") }}
                                  </h3><br>
                                  <h3 class="text-left">
                                    {{ total_storage_business }}
                                  </h3>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col class="ml-6" cols="6">
                                  <v-progress-linear
                                    :color="color_storage"
                                    height="10"
                                    rounded
                                    :value="percentstorage"
                                  ></v-progress-linear
                                  ><br />
                                  <h4>
                                    {{ $t("packagenew.used_space") }}
                                  </h4><br>
                                  <h4>
                                    {{ use_storage_business }}/{{ total_storage_business }}
                                  </h4>
                                </v-col>
                                <v-col cols="5">
                                  <v-img class="ml-3 " :src="require('@/assets/img/package2.png')" max-width="80" max-height="80" />
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="ml-4"></v-divider>
                      <v-list-item class="ml-4">
                        <v-list-item-content>
                          <v-layout row wrap justify-center>
                            <v-flex lg12 xs12>
                              <v-row>
                                <v-col class="ml-6" cols="12">
                                  <h3>{{ $t("packagenew.total_users") }}</h3>
                                </v-col>
                                <v-col class="ml-6" cols="12">
                                  <v-progress-linear
                                    :color="color_user"
                                    height="10"
                                    rounded
                                    :value="percentuser"
                                  ></v-progress-linear
                                  ><br />
                                  <h4><v-icon color="#5a77ff">groups</v-icon>{{ use_user }} User</h4>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                  </v-layout>
                </v-card> -->
                <h3 class="text-center mt-10 mb-1" :style="darkModeText">
                  {{ $t("packagenew.title_package") }}
                </h3>
                <!-- <v-row class="text-center">
                  <v-col cols="12" class="py-2">
                    <v-btn-toggle
                      v-model="packageTypeSelected"
                      :color="packageTypeSelected ? '#2D4E9A' : 'grey lighten-1'"
                      borderless
                      mandatory
                      @change="getdatapackage(packageTypeSelected)"
                    >
                      <v-btn value="M">
                        <span>{{ $t("packagenew.month") }}</span>
                      </v-btn>
                      <v-btn value="Y">
                        <span>{{ $t("packagenew.year") }}</span>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row> -->
              </v-flex>
              <br />

              <!-- package [new] -->
              <v-flex lg12 xs12 class="mx-4">
                <v-sheet elevation="0" id="borderradius" class="pa-4" outlined>                  
                  <v-tabs
                    v-model="packageTypeSelected"
                    :color="color.theme"
                    left
                    class="mb-4"
                    @change="getdatapackage(packageTypeSelected)"
                  >
                    <v-tab href="#M">{{ $t("packagenew.month") }}</v-tab>
                    <v-tab href="#Y">{{ $t("packagenew.year") }}</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="packageTypeSelected">
                    <v-tab-item
                      v-for="i in ['M','Y']"
                      :key="i"
                      :value="i"
                    >
                      <v-card 
                        id="borderradius"
                        outlined
                        class="mb-4"
                        v-for="item in listpackage"
                        :key="item.id"
                        :class="item.id === activeIndex ? 'primary' : 'lighten-1'"
                      >
                        <v-layout justify-left class="pt-2">
                          <v-card-title 
                            class="text-h5"
                            :style="item.id === activeIndex ? price_dark : $vuetify.theme.dark ? price_dark : price_"
                          >
                            {{ numberWithCommas(item.package_price) }}
                          </v-card-title>
                            <p 
                              class="text-center mt-6" 
                              :style="item.id === activeIndex ? 'fornt-size: 25; color: #FFFFFF;' : 'fornt-size: 25; color: #000000;'" 
                              v-if="packageTypeSelected === 'M'"
                            >
                              {{ $t("packagenew.baht_month") }}
                            </p>
                            <p 
                              class="text-center mt-6" 
                              :style="item.id === activeIndex ? 'fornt-size: 25; color: #FFFFFF;' : 'fornt-size: 25; color: #000000;'" 
                              v-else
                            >
                              {{ $t("packagenew.baht_year") }}
                            </p>
                        </v-layout>
                        <v-expansion-panels 
                          flat
                          :dark="item.id === activeIndex ? true : false"
                        >
                          <v-expansion-panel style="background-color: #00000000">
                            <v-expansion-panel-header :color="item.id === activeIndex ? 'primary' : 'lighten-1'">
                              <v-layout justify-left>
                                <span class="text-left pr-4" style="fornt-size: 14">{{ item.package_name }}</span>
                                <span
                                  :style="item.id === activeIndex ? 'color: #FFFFFF;' : 'color: #1279BC;'"
                                >
                                  {{ item.package_storage }} 
                                </span>
                              </v-layout>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content 
                              :color="item.id === activeIndex ? '#1279bc' : $vuetify.theme.dark ? '#363636' : '#FAFAFA'" 
                              class="#FFFFFF" 
                              :dark="item.id === activeIndex ? true : false"
                            >
                              <v-list 
                                flat 
                                elevation="0" 
                                :color="item.id === activeIndex ? '#1279bc' : $vuetify.theme.dark ? '#363636' : '#FAFAFA'" 
                                class="#FFFFFF ml-n4" 
                                :dark="item.id === activeIndex ? true : false"
                              >
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Manage User</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Upload/Download/Share</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>accessibility</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Restrict Access</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>find_in_page</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Advance Search</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Connect Through API</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title> Send File to e-Mail</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <v-flex lg12 xs12 class="mr-4 ml-4">
                        </v-flex>
                        <v-flex lg12 xs12 class="mr-4 ml-4 ma-4">
                          <v-btn block depressed color="#5AB685" dark @click="fn_selectpackage(item.id === activeIndex, item, 1)">
                            {{ $t("packagenew.choose_package") }}
                          </v-btn>
                        </v-flex>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <!-- package -->
                  <!-- <v-slide-group v-model="model" class="mx-auto" center-active show-arrows>
                    <v-slide-item
                      active-class="primary"
                      v-for="(item, text) in listpackage"
                      :key="text"
                      class="ma-1"
                      v-slot="{ active, toggle }"
                    >
                      <v-card
                        width="250"
                        outlined
                        hover
                        :color="active ? 'primary' : 'lighten-1'"
                        :dark="active ? true : false"
                      >
                        <v-layout justify-left class="pt-2">
                          <v-card-title :style="active ? price_dark : $vuetify.theme.dark ? price_dark : price_">{{ numberWithCommas(item.package_price) }}</v-card-title>
                          <p class="text-center mt-6" style="fornt-size:25" v-if="packageTypeSelected === 'M'">{{ $t("packagenew.baht_month") }}</p>
                          <p class="text-center mt-6" style="fornt-size:25" v-else>{{ $t("packagenew.baht_year") }}</p>
                        </v-layout>
                        <v-expansion-panels 
                          flat
                          :dark="active ? true : false"
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header :color="active ? 'primary' : 'lighten-1'">
                              <v-layout justify-left>
                                <h2 class="text-left" style="fornt-size:24">{{ item.package_name }}<br><br>
                                  <span
                                    class="text-left"
                                    :style="active ? 'color: #FFFFFF;' : 'color: #1279BC; '"
                                  >
                                    {{ item.package_storage }} 
                                  </span>
                                </h2>
                              </v-layout>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content :color="active ? '#1279bc' : $vuetify.theme.dark ? '#363636' : '#FAFAFA'" class="#FFFFFF" :dark="active ? true : false">
                                  <v-list flat elevation="0" :color="active ? '#1279bc' : $vuetify.theme.dark ? '#363636' : '#FAFAFA'" class="#FFFFFF ml-n4" :dark="active ? true : false">
                                    <v-list-item dense>
                                      <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Manage User</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                      <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>Upload/Download/Share</v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    <v-list-item dense>
                                      <v-list-item-icon><v-icon>accessibility</v-icon></v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Restrict Access</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item dense>
                                      <v-list-item-icon><v-icon>find_in_page</v-icon></v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>Advance Search</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Connect Through API</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title> Send File to e-Mail</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <v-flex lg12 xs12 class="mr-4 ml-4">
                        </v-flex>
                        <v-flex lg12 xs12 class="mr-4 ml-4 ma-4">
                          <div class="text-center" @click="toggle">
                            <v-btn rounded color="#5AB685" dark @click="fn_selectpackage(active, item, 1)">
                              {{active === true ? 'แพ็กเกจปัจจุบัน' : 'เลือกแพ็กเกจ'}} {{ $t("packagenew.choose_package") }}
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group> -->
                  <p class="font-weight-regular mt-2 ml-2" style="color:red;">
                    {{ $t("packagenew.vat") }}
                  </p>
                </v-sheet>
              </v-flex>
              
              <!-- package  addon-->
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <h3 class="text-center mt-10 mb-1 ml-2" :style="darkModeText">
                  {{ $t("addon_package.addon_package") }}
                </h3>
                <p class="text-left font-weight-regular mr-2 ml-2 mt-2" :style="$vuetify.theme.dark ? 'color: #bfbfbf' : 'color: #757575;'">
                  {{ $t("addon_package.addon_title") }}
                </p>
                <!-- ตาราง addon -->
                <div>
                  <v-data-table
                    v-model="filemultipleaddon"
                    :headers="headers"
                    :items="listpackage_addon"
                    :no-data-text="$t('tablefile.empty_resend')"
                    :single-select="false"
                    :search="search"
                    item-key="id"
                    item-selected
                    :show-select="statusmutipleaddon"
                    class="elevation-0"
                    :items-per-page="listpackage_addon.length"
                    :hide-default-footer="true"
                    mobile-breakpoint="0"
                  >
                    <template v-slot:[`header.package_name`]="{ header }">
                      <span
                        class="pointer"
                        :style="headerTable"
                      >{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:[`header.package_price`]="{ header }">
                      <span
                        class="pointer"
                        @click="(multiSort = !multiSort), (sortby = 'name'), (clickSort = true), sortFile(rootfile)"
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <template v-slot:[`header.package_num_package`]="{ header }">
                      <span
                        class="pointer"
                        @click="(multiSort = !multiSort), (sortby = 'owner'), (clickSort = true), sortFile(rootfile)"
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <template v-slot:item="props">
                      <tr @contextmenu="test($event, props.item)" style="cursor: pointer">
                        <td width="25%">{{ props.item.package_name }}</td>
                        <td width="30%" class="text-center">{{ numberWithCommas(props.item.package_price) }}</td>
                        <td width="45%" class="text-center">
                          <v-layout row wrap d-flex justify-center align-center>
                            <v-flex xs4 lg4>
                              <v-btn
                                class="white--text elevation-0"
                                fab
                                small                              
                                :color="color.theme"
                                @click="
                                  props.item.package_num_package -=
                                    1 | fn_minus_package(props.index, props.item, (props.item.package_num_package -= 1))
                                "
                                :disabled="
                                  listpackage_current_main.length == 0 && list_selected_package_main.length == 0 
                                    ? true
                                    : props.item.package_num_package == 0 
                                    ? true
                                    : false
                                "
                              >
                                <v-icon>mdi-minus</v-icon>
                              </v-btn>
                            </v-flex>
                            <v-flex xs4 lg4>
                              <v-text-field 
                                hide-details
                                outlined
                                dense
                                class="text-center py-4"
                                v-model="props.item.package_num_package"
                                readonly
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs4 lg4>
                              <v-btn
                                class="white--text elevation-0"
                                fab
                                small
                                :color="color.theme"
                                @click.prevent="
                                  props.item.package_num_package +=
                                    1 | fn_plus_package(props.index, props.item, (props.item.package_num_package += 1))
                                "
                                :disabled="listpackage_current_main.length == 0 && list_selected_package_main.length == 0 ? true  : false"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
               <!-- <v-card class="mx-auto mt-8" outlined id="borderradius">
                <v-row
                  class="mb-4 ma-2"
                  justify="space-between"
                >
                  <v-col class="text-left">
                    <span
                      class="text-h2 font-weight-light"
                      v-text="sliderstorage_addon"
                    ></span>
                    <span class="subheading font-weight-light mr-1">GB</span>
                  </v-col>
                  <v-col class="text-right">
                    <span
                      class="text-h2 font-weight-light"
                      v-text="pricetotal_addon"
                    ></span>
                    <span class="subheading font-weight-light mr-1">บาท</span>
                  </v-col>
                </v-row>
                <v-row class="ma-2">
                   <v-col cols="12">
                    <v-slider
                      v-model="sliderstorage_addon"
                      class="pt-6"
                      thumb-label="always"
                      step="10"
                    ></v-slider>
                  </v-col>
                </v-row>
               </v-card> -->
               <v-divider></v-divider>
              </v-flex>
                <!-- <v-divider></v-divider> -->

              <!-- สรุปคำสั่งซื้อ -->
              <v-flex lg12 xs12 class="mr-4 ml-4 mt-4">
                <h3 class="text-left mt-8 ml-2" :style="darkModeText">
                  {{ $t("Order_summary.Order_summary") }}
                </h3>
                <v-card class="mx-auto mt-8" outlined id="borderradius">
                  <v-list three-line>
                    <template>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t("Order_summary.main_package") }}</v-list-item-title>
                          <v-list-item-subtitle v-if="list_selected_package_main.length === 0"
                            >{{ $t("Order_summary.not_choose") }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle v-else>{{
                            list_selected_package_main_show[0].namepackage +
                              " " +  " | " +  " " +  
                              list_selected_package_main_show[0].storage 
                           
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-title v-if="list_selected_package_main.length === 0" :style="darkModeText">
                            0 {{$t("Order_summary.baht")}} </v-list-item-title
                          >
                          <v-list-item-title v-else :style="darkModeText">
                            {{ numberWithCommas(list_selected_package_main_show[0].price) + " " + $t("Order_summary.baht") }}</v-list-item-title
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list>
                    <template>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t("Order_summary.addon_package") }}</v-list-item-title>
                          <v-list-item-subtitle v-if="listpackage_addon.length == 0">คุณยังไม่เลือกแพ็กเก็จเสริม</v-list-item-subtitle>
                          <v-list-item-subtitle v-for="(item, id) in listpackage_addon" :key="id" >{{ item.package_num_package != 0 ? item.package_name + " * " + item.package_num_package + " , " : ""}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-title v-if="listpackage_addon.length == 0" :style="darkModeText">0 บาท</v-list-item-title>
                          <v-list-item-title :style="darkModeText" v-else>{{numberWithCommas(pricetotal_addon) + " " + $t("Order_summary.baht")}}</v-list-item-title>
                          <v-spacer></v-spacer>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list>
                    <template>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>VAT 7 %</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <!-- <v-list-item-title style="color:#000000; fornt-size:25;" v-if="list_selected_package_main.length === 0">
                            0บาท</v-list-item-title
                          > -->
                          <v-list-item-title :style="darkModeText">{{ numberWithCommas(vat) + " " + $t("Order_summary.baht") }}</v-list-item-title>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list>
                    <template>
                      <v-list-item style="color:#ECFAFF;">
                        <v-list-item-content>
                          <v-list-item-title style="line-height:26px" >{{ $t("Order_summary.total") }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <!-- <v-list-item-title style="color:#000000; fornt-size:25;" v-if="list_selected_package_main.length === 0">
                            0 บาท</v-list-item-title
                          > -->
                          <v-list-item-title :style="darkModeText">{{
                            numberWithCommas(pricetotal) + " " + $t("Order_summary.baht") 
                          }}</v-list-item-title>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>

                <!-- <div class="text-center">
                  <v-btn
                    class="text-decoration-underline ma-6"
                    :style="taxInvoice == true ? 'color:#5AB685;  fornt-size:30;' : 'color:#1279BC; fornt-size:30;'"
                    @click="fn_tax_invoice()"
                    text
                  >
                    {{
                      taxInvoice == true
                        ? "✔ กรอกใบกำกับภาษีเรียบร้อย สามารถคลิกที่นี่เพื่อแก้ไข"
                        : "คลิกที่นี่เพื่อกรอกข้อมูลใบกำกับภาษี"
                    }}
                  </v-btn>
                </div> -->

                <!-- <v-card class="mx-auto mt-8" outlined id="borderradius">
                  <v-list subheader three-line>
                    <v-list-item v-for="folder in folders" :key="folder.title">
                      <v-list-item-content>
                        <v-list-item-title v-text="folder.title" style="color:#000000; fornt-size:25;"></v-list-item-title>

                        <v-list-item-subtitle v-text="folder.subtitle"></v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-title v-text="folder.price" style="color:#000000; fornt-size:25;">บาท</v-list-item-title>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="folder < folders.length - 1" :key="folder.title"></v-divider>
                  </v-list>
                </v-card> -->
              </v-flex>
              
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <p class="text-left font-weight-regular mt-2 ml-4" style="color:red;">
                  {{ $t("Order_summary.vat") }}
                </p>
                <br />
                <v-divider v-if="taxInvoice === true"></v-divider>
              </v-flex>
              
              <!-- ที่อยู่ใบกำกับภาษี -->
              <v-flex lg12 xs12 class="mr-4 ml-4" v-if="taxInvoice === true">
                <h3 class="text-left mt-8 ml-2" :style="darkModeText">
                  {{ $t("taxinvoice_address.taxinvoice_address") }}
                </h3>
                <v-card class="mx-auto mt-8" outlined id="borderradius">
                     <div>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs11 lg11 class="pl-9 pt-4"><p>{{$t("taxinvoice_address.purchaser") + "     " + name_invoice_th}}</p></v-flex>
                      <!-- <v-flex xs10 lg10 class="pl-4 pt-4"><p > {{ tax_num  }} </p></v-flex> -->
                    </v-layout>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs11 lg11 class="pl-9"><p>{{$t("taxinvoice_address.address")  + "     " +  $t("taxinvoice_address.address_detail")}}</p></v-flex>
                    </v-layout>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs11 lg11 class="pl-9"><p>{{$t("taxinvoice_address.tax_identification") + "      " +  tax_num + "   " + branch_name }}</p></v-flex>
                    </v-layout>
                  </div>          
                </v-card>
              </v-flex>
              
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <div class="text-center">
                  <v-btn v-if="taxInvoice == true"
                    class="text-decoration-underline my-6"
                    style="color:#5AB685;"
                    @click="fn_tax_invoice()"
                    text
                  >
                    <span class="text-wrap" style="width: 80%;">{{ $t("taxinvoice_address.edit_tax") }}</span>
                  </v-btn>
                  <v-btn
                    v-else
                    class="text-decoration-underline my-6"
                    style="color:#1279BC;"
                    @click="fn_tax_invoice()"
                    text
                  >
                    <span class="text-wrap" style="width: 80%;">{{ $t("taxinvoice_address.click_here") }}</span>
                  </v-btn>
                </div>
              </v-flex>

              <v-flex lg12 xs12 class="mr-4 ml-4 mb-2">
                <v-layout>
                  <v-flex xs6>
                    <v-btn block outlined :color="color.theme" @click="fn_clear()">
                      {{ $t("packagenew.clear") }}
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 class="ml-4">
                    <v-btn
                      class="white--text"
                      block
                      :color="color.theme"
                      :disabled="listpackage_current_main.length === 0"
                      @click="fn_updatepackage()"
                      v-if="$route.query.typepay === 1 || listpackage_current.length != 0"
                    >
                      {{ $t("packagenew.update_package") }}
                    </v-btn>
                    <v-btn
                      class="white--text"
                      block
                      :color="color.theme"
                      :disabled="list_selected_package_main.length === 0"
                      @click="fn_checktax()"
                      v-else
                    >
                      <!-- @click="opendialogchecktaxinvioce = true" -->
                      {{ $t("packagenew.pay") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-content>
    <tax_invoice
      :show="opentaxinvoice"
      :checktaxbtn="checktaxbtn"
      @closedialog="(opentaxinvoice = false), fn_check_tax_invoice(), (checktaxbtn = 0)"
      @payment="(opentaxinvoice = false), fn_buypackage(), (checktaxbtn = 0)"
      @closedialognotpay="(opentaxinvoice = false), (checktaxbtn = 0)"
    ></tax_invoice>
    <checktaxinvioce
      :show="opendialogchecktaxinvioce"
      @closedialog="(opendialogchecktaxinvioce = false), fn_buypackage(), (checktaxbtn = 0)"
      @payment="(opendialogchecktaxinvioce = false), fn_tax_invoice_bybtnpay()"
    ></checktaxinvioce>
    <dialogshowdetailupdatepackage
      :show="opendialogshowdetailupdatepackage"
      @closedialog="opendialogshowdetailupdatepackage = false"
      @reload="(opendialogshowdetailupdatepackage = false), fn_reload()"
      @buy_addon="fn_buypackage()"
      @payment="(opentaxinvoice = false), fn_buypackage(), (checktaxbtn = 0)"
      :detail="listpackageupdate_detail"
      :detail_main="listpackageupdate_detail_main"
      :detail_addon="listpackageupdate_detail_addon"
      :all_name="all_name"
      :paid_dtm="paid_dtm"
      :total_amount="total_amount"
      :total_amount_vat="total_amount_vat"
      :listpackage_current_main_="listpackage_current_main"
      :listpackage_current_addon_="listpackage_current_addon"
      :pricetotal_addon_="pricetotal_addon"
      :listpackage_addon_="listpackage_addon"
      :vat_="vat"
      :listpackage_addon_show_Update="listpackage_addon_show_Update"
      :buy_package_main="list_selected_package_main_update"
      :buy_package_addon="listpackage_addon_"
      :list_selected_package_main_show="list_selected_package_main_show"
      :execution="execution"
      :listpackageupdate_detail_update_addon="listpackageupdate_detail_update_addon"
      :listpackageupdate_detail_update_main="listpackageupdate_detail_update_main"
      :listpackageupdate_detail_r="listpackageupdate_detail_r"
      :listpackageupdate_detail_r_addon="listpackageupdate_detail_r_addon"
      :listpackageupdate_detail_r_main="listpackageupdate_detail_r_main"
      :taxInvoice="taxInvoice"
    ></dialogshowdetailupdatepackage>
  </v-app>
</template>
<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { slider, slideritem } from "vue-concise-slider";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
const tax_invoice = () => import("../components/optional/dialog-tax-invoice");
const checktaxinvioce = () => import("../components/optional/dialog-checktaxinvioce.vue");
const dialogshowdetailupdatepackage = () => import("../components/optional/dialog-showdetailupdatepackage.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data: () => ({
    packageTypeSelected: 'M',
    activeIndex: null,
    sliderstorage_addon:10,
    loaddataprogress: false,
    address:'',
    tax_num:'',
    noaddress:'',
    building :'',
    floor:'',
    road :'',
    subdistrict:'',
    district:'',
    province:'',
    postcode:'',
    typebusiness:'',
    name_business_th:'',
    typebusiness_eng:'',
    radios :'',
    branch_no :'',
    branch_name:'',
    email_business:'',
    tel_biz:'',
    mooban:'',
    no_moo :'',
    noroom :'',
    yaek :'',
    soi :'',
    fax :'',
    name_invoice_th :'',
    phone_number_biz:'',
    list_selected_package_main_showselect:[],
    listpackageupdate_detail_main: [],
    listpackageupdate_detail_addon:[],
    listpackageupdate_detail_r:[],
    listpackageupdate_detail_r_main:[],
    listpackageupdate_detail_r_addon:[],
    listpackageupdate_detail_update_addon: [],
    listpackageupdate_detail_update_main: [],
    execution: "",
    vat: 0,
    listpackage_addon_show_Update: [],
    listpackageupdate_detail: [],
    all_name: "",
    paid_dtm: "",
    total_amount: 0,
    total_amount_vat: 0,
    listpackage_current: [],
    opendialogshowdetailupdatepackage: false,
    opendialogchecktaxinvioce: false,
    dataaddon_Index_addon: "",
    dataaddon_Index: "",
    list_selected_package_main_update: [],
    listpackage_current_main: [],
    listpackage_current_addon: [],
    checktaxbtn: 0,
    pricetotal: 0,
    pricetotal_addon: 0,
    listpackage_addon_: [],
    listpackage_addon_show: [],
    list_selected_package_main: [],
    list_selected_package_main_show: [],
    setcolor: false,
    filemultipleaddon: [],
    statusmutipleaddon: false,
    search: "",
    percentstorage: 0,
    percentuser: 0,
    color_user: "",
    color_storage: "",
    num_package_limit: 0,
    total_storage_business: "",
    use_storage_business: "",
    total_user: 0,
    use_user: 0,
    listpackage_addon: [],
    id_mypackage: "",
    listpackage: [],
    mypackage: [],
    listpackage_total: [],
    URL_payment: "",
    model: null,
    list_packge: [],
    swiperOption: {
      slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    isFooterDesktop: true,
    isFooterMobile: false,
    showDataStorage: "",
    headers: [
      {
        text: "packagenew.package",
        align: "left",
        value: "package_name",
        width: "25%",
        sortable: false,
      },
      {
        text: "packagenew.price",
        align: "center",
        value: "package_price",
        width: "30%",
        sortable: false,
      },
      {
        text: "packagenew.amount",
        value: "package_num_package",
        width: "45%",
        align: "center",
        sortable: false,
      },
    ],
    // folders: [
    //   {
    //     title: "แพ็กเก็จหลัก",
    //     subtitle: "คุณยังไม่เลือกแพ็กเก็จหลัก",
    //     price: "00000000000.00",
    //   },
    //   {
    //     title: "แพ็กเก็จเสริม",
    //     subtitle: "คุณยังไม่เลือกแพ็กเก็จเสริม",
    //     price: "0.00",
    //   },
    //   {
    //     title: "VAT 7 %",
    //     subtitle: "",
    //     price: "0.00",
    //   },
    //   {
    //     title: "รวมทั้งหมด",
    //     subtitle: "",
    //     price: "0.00",
    //   },
    // ],
    // percentageStorage: 90,
    // percentagestoage_setcolor:"red"
    opentaxinvoice: false,
    taxInvoice: false,
  }),
  components: {
    InfiniteLoading,
    VueFullScreenFileDrop,
    onebox_toolbar,
    Swiper,
    SwiperSlide,
    tax_invoice,
    checktaxinvioce,
    dialogshowdetailupdatepackage,
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "storage_usage"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    btnStates() {
      return this.buttons.map((btn) => btn.state);
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headergb() {
      return "font-size: 40px";
    },
    header() {
      return "font-size: 30px";
    },
    price_() {
      return "color:black;" + "font-weight: 600; font-size:28px;";
    },
    price_dark() {
      return "color:white;" + "font-weight: 600; font-size:28px;";
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:14px;";
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {    
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    darkModeText() {
      return this.$vuetify.theme.dark ? "color: white;" : "color: black"
    },
  },
  watch: {
    sliderstorage_addon: function(val) {
      this.fn_pricetotal_addon();
    }
  },
  methods: {
    fn_pricetotal_addon(){
      let total = this.sliderstorage_addon *7
      return total
    },
    fn_checktax(){
      if(this.taxInvoice === true){
        this.fn_buypackage();
      }else{
        this.checktaxbtn = 1;
        this.opentaxinvoice = true;
      }
    },
    async fn_updatepackage() {

    // if(this.taxInvoice === true){
      this.loaddataprogress = true;
      this.listpackageupdate_detail = [];
      this.listpackageupdate_detail_update_addon = [];
      this.listpackageupdate_detail_update_main = [];
      this.listpackageupdate_detail_r = [];
      this.listpackageupdate_detail_r_main = [];
      this.listpackageupdate_detail_r_addon = [];
      this.listpackageupdate_detail_main = [];
      this.listpackageupdate_detail_addon = [];

      let payload = {
        account_id: this.dataAccountId,
        data_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
        type: this.dataAccountActive.type === 'Citizen' ? 'citizen' :'business',
        order_desc: "",
        list_packge_main: this.list_selected_package_main_update,
        list_packge_addon: this.listpackage_addon_,
      };
      // console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/description_update_packege", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          // console.log("res*", response.data.result);
          if (response.data.status === "OK") {
            this.loaddataprogress = false;
            // console.log("response", response);

            this.all_name = response.data.all_name;
            this.paid_dtm = response.data.paid_dtm;
            this.total_amount = response.data.total_amount;
            this.total_amount_vat = response.data.total_amount_vat;
            this.vat = response.data.vat.toFixed(2);
            this.execution = response.data.execution
            console.log(this.vat);

            for (var i = 0; i < response.data.list_details.length; i++) {
              let datadetail = {};
              datadetail["amount"] = response.data.list_details[i].amount;
              datadetail["difference_day"] = response.data.list_details[i].difference_day;
              datadetail["difference_unit_price"] = response.data.list_details[i].difference_unit_price;
              datadetail["package_name"] = response.data.list_details[i].package_name;
              datadetail["quantity"] = response.data.list_details[i].quantity;
              datadetail["quantity_add"] = response.data.list_details[i].quantity_add;
              datadetail["quantity_old"] = response.data.list_details[i].quantity_old;
              datadetail["unit_price"] = response.data.list_details[i].unit_price;
              datadetail["execution"] = response.data.list_details[i].execution;
              datadetail["name_buyer"] = response.data.list_details[i].name_buyer;
              datadetail["orderidref"] = response.data.list_details[i].orderidref;
              datadetail["package_id"] = response.data.list_details[i].package_id;
              datadetail["type_package"] = response.data.list_details[i].type_package;
              datadetail["package_name_en"] = response.data.list_details[i].package_name_en;
              datadetail["package_number_user"] = response.data.list_details[i].package_number_user;
              datadetail["package_storage"] = response.data.list_details[i].package_storage;

              this.listpackageupdate_detail.push(datadetail);

              if(response.data.list_details[i].type_package === 'main'){
                this.listpackageupdate_detail_main.push(datadetail);
              }else{
                this.listpackageupdate_detail_addon.push(datadetail);
                }
            }
            
            for(var i = 0; i < response.data.list_items_increase.length; i++ ){

              let datadetail_update = {};
              datadetail_update["amount"] = response.data.list_items_increase[i].amount;
              datadetail_update["difference_day"] = response.data.list_items_increase[i].difference_day;
              datadetail_update["difference_unit_price"] = response.data.list_items_increase[i].difference_unit_price;
              datadetail_update["package_name"] = response.data.list_items_increase[i].package_name;
              datadetail_update["quantity"] = response.data.list_items_increase[i].quantity;
              datadetail_update["quantity_add"] = response.data.list_items_increase[i].quantity_add;
              datadetail_update["quantity_old"] = response.data.list_items_increase[i].quantity_old;
              datadetail_update["unit_price"] = response.data.list_items_increase[i].unit_price;
              datadetail_update["execution"] = response.data.list_items_increase[i].execution;
              datadetail_update["name_buyer"] = response.data.list_items_increase[i].name_buyer;
              datadetail_update["orderidref"] = response.data.list_items_increase[i].orderidref;
              datadetail_update["package_id"] = response.data.list_items_increase[i].package_id;
              datadetail_update["type_package"] = response.data.list_items_increase[i].type_package;
              datadetail_update["package_name_en"] = response.data.list_items_increase[i].package_name_en;
              datadetail_update["package_number_user"] = response.data.list_items_increase[i].package_number_user;
              datadetail_update["package_storage"] = response.data.list_items_increase[i].package_storage;

              if(datadetail_update["type_package"] === 'add_on'){
                this.listpackageupdate_detail_update_addon.push(datadetail_update);
              }else{
                this.listpackageupdate_detail_update_main.push(datadetail_update);
              }
            }

            for(var i = 0; i < response.data.list_items_reduce.length; i++ ){

              let datadetail_update_r = {};
               datadetail_update_r["amount"] = response.data.list_items_reduce[i].amount;
              datadetail_update_r["difference_day"] = response.data.list_items_reduce[i].difference_day;
              datadetail_update_r["difference_unit_price"] = response.data.list_items_reduce[i].difference_unit_price;
              datadetail_update_r["package_name"] = response.data.list_items_reduce[i].package_name;
              datadetail_update_r["quantity"] = response.data.list_items_reduce[i].quantity;
              datadetail_update_r["quantity_add"] = response.data.list_items_reduce[i].quantity_add;
              datadetail_update_r["quantity_old"] = response.data.list_items_reduce[i].quantity_old;
              datadetail_update_r["unit_price"] = response.data.list_items_reduce[i].unit_price;
              datadetail_update_r["execution"] = response.data.list_items_reduce[i].execution;
              datadetail_update_r["name_buyer"] = response.data.list_items_reduce[i].name_buyer;
              datadetail_update_r["orderidref"] = response.data.list_items_reduce[i].orderidref;
              datadetail_update_r["package_id"] = response.data.list_items_reduce[i].package_id;
              datadetail_update_r["type_package"] = response.data.list_items_reduce[i].type_package;
              datadetail_update_r["package_name_en"] = response.data.list_items_reduce[i].package_name_en;
              datadetail_update_r["package_number_user"] = response.data.list_items_reduce[i].package_number_user;
              datadetail_update_r["package_storage"] = response.data.list_items_reduce[i].package_storage;


              this.listpackageupdate_detail_r.push(datadetail_update_r);

              if(datadetail_update_r["type_package"] === 'add_on'){
                this.listpackageupdate_detail_r_addon.push(datadetail_update_r);
              }else{
                this.listpackageupdate_detail_r_main.push(datadetail_update_r);
              }
              // if(datadetail_update["type_package"] === 'add_on'){
              //   this.listpackageupdate_detail_update_addon.push(datadetail_update);
              // }else{
              //   this.listpackageupdate_detail_update_main.push(datadetail_update);
              // }
            }
            console.log("+++++++",this.listpackageupdate_detail_update);

            if(this.execution !== 'N'){
              this.opendialogshowdetailupdatepackage = true;
            }else{
              Toast.fire({
              icon: "error",
              title: "คุณไม่ได้ทำการอัปเดตแพ็กเกจ",
            });
            }

          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: localStorage.getItem("lang") === 'en' ? response.data.errorMessage : response.data.errorMessage_th,
            });
            this.getdatapackage(this.packageTypeSelected);
            this.fn_getstorage_user();
            // this.fn_check_tax_invoice();
            // if(this.taxInvoice === true){
            //   this.fn_get_tax_invoice();
            // }
          }
        });
    // }else{
    //   this.checktaxbtn = 1;
    //   this.opentaxinvoice = true;
    // }
    },
    onScroll() {
      const showsize = window.innerWidth;
      this.isFooterDesktop = showsize > 600;
      this.isFooterMobile = showsize < 600;
    },
    convertstorageToByte(_storage) {
      // console.log(_storage);
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1024 : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1048576 : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1073741824 : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number" ? splitStorage[0] * 1099511627776 : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] : parseFloat(splitStorage[0]);
      }
      // console.log("convertstorageToByte",Math.ceil(databyte));
      return Math.ceil(databyte);
    },
    async getdatapackage(type) {
      this.loaddataprogress = true;
      this.listpackage = [];
      this.listpackage_addon = [];
      this.listpackage_addon_ = [];
      this.list_selected_package_main_update = [];
      let payload;
      if (type === "M") {
        payload = {
          limit: "10",
          offset: "0",
          period: "M",
          data_type: this.dataAccountActive.type === 'Citizen' ? 'citizen' : 'business'
        };
      } else {
        payload = {
          limit: "10",
          offset: "0",
          period: "Y",
          data_type: this.dataAccountActive.type === 'Citizen' ? 'citizen' : 'business'
        };
      }
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + `/api/get_package`, payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          // console.log("res==========", response);
          // console.log("id_mmm", this.id_mypackage);
          if (response.data.status === "OK") {
            this.loaddataprogress = false;
            for (var i = 0; i < response.data.package_main.length; i++) {
              let datapackage = {};
              datapackage["cre_dtm"] = response.data.package_main[i].cre_dtm;
              datapackage["feature_extension"] = response.data.package_main[i].feature_extension;
              datapackage["id"] = response.data.package_main[i].id;
              datapackage["package_name"] = response.data.package_main[i].package_name;
              datapackage["package_name_th"] = response.data.package_main[i].package_name_th;
              datapackage["package_price"] = response.data.package_main[i].package_price;
              datapackage["package_storage"] = response.data.package_main[i].package_storage;
              datapackage["package_number_user"] = response.data.package_main[i].number_user;
              this.listpackage.push(datapackage);
              this.list_selected_package_main_update.push({ package_id: response.data.package_main[i].id, quantity: 0 });
            }

            for (var i = 0; i < response.data.package_addon.length; i++) {
              let datapackage_addon = {};
              datapackage_addon["cre_dtm"] = response.data.package_addon[i].cre_dtm;
              datapackage_addon["feature_extension"] = response.data.package_addon[i].feature_extension;
              datapackage_addon["id"] = response.data.package_addon[i].id;
              datapackage_addon["package_name"] = response.data.package_addon[i].package_name;
              datapackage_addon["package_name_th"] = response.data.package_addon[i].package_name_th;
              datapackage_addon["package_price"] = response.data.package_addon[i].package_price;
              datapackage_addon["package_storage"] = response.data.package_addon[i].package_storage;
              datapackage_addon["package_number_user"] = response.data.package_addon[i].number_user;
              datapackage_addon["package_num_package"] = 0;
              datapackage_addon["package_price_sum"] = 0;

              this.listpackage_addon.push(datapackage_addon);
            }

            for (var i = 0; i < response.data.package_addon.length; i++) {
              let datapackage_addon_ = {};
              datapackage_addon_["package_id"] = response.data.package_addon[i].id;
              datapackage_addon_["quantity"] = 0;
              this.listpackage_addon_.push(datapackage_addon_);
            }

            for (var i = 0; i < response.data.package_addon.length; i++) {
              let datapackage_addon_sowupdate = {};
              datapackage_addon_sowupdate["cre_dtm"] = response.data.package_addon[i].cre_dtm;
              datapackage_addon_sowupdate["feature_extension"] = response.data.package_addon[i].feature_extension;
              datapackage_addon_sowupdate["id"] = response.data.package_addon[i].id;
              datapackage_addon_sowupdate["package_name"] = response.data.package_addon[i].package_name;
              datapackage_addon_sowupdate["package_name_th"] = response.data.package_addon[i].package_name_th;
              datapackage_addon_sowupdate["package_price"] = response.data.package_addon[i].package_price;
              datapackage_addon_sowupdate["package_storage"] = response.data.package_addon[i].package_storage;
              datapackage_addon_sowupdate["package_number_user"] = response.data.package_addon[i].number_user;
              datapackage_addon_sowupdate["package_num_package"] = 0;
              datapackage_addon_sowupdate["package_price_sum"] = 0;
              datapackage_addon_sowupdate["quantity"] = 0;

              this.listpackage_addon_show_Update.push(datapackage_addon_sowupdate);
            }

            // console.log("listpackage", this.listpackage);
            // console.log("listpackage_addon", this.listpackage_addon);
            // console.log("listpackage_addon_", this.listpackage_addon_);
            // console.log("list_selected_package_main_update", this.list_selected_package_main_update);

            this.fn_checkpackage();
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    async fn_buypackage() {
      if(this.listpackage_current_main.length != 0){
        this.URL_payment = "";
        let payload;
        payload = {
          account_id: this.dataAccountId,
          data_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
          type: this.dataAccountActive.type === 'Citizen' ? 'citizen': 'business',
          order_desc: "",
          list_packge_main: this.list_selected_package_main,
          list_packge_addon: this.listpackage_addon_,
          execution:this.execution,
          type_invoice:"Y"
        };
     // console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/update_payment_package_storage", payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            if (response.data.status === "OK") {
          
              console.log("response", response);
              this.status_response = 'OK';
              this.loaddataprogress = false;
              this.$emit("closedialog");
              if(this.execution === 'R'){
                this.opendialogconfirmupdatepackage = true;
              }else{
                this.URL_payment = response.data.result
                this.gotopay();
              }

            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      }else{
        this.URL_payment = "";
        let payload;
        payload = {
          account_id: this.dataAccountId,
          data_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
          type: this.dataAccountActive.type === 'Citizen' ? 'citizen': 'business',
          order_desc: "",
          list_packge_main: this.list_selected_package_main,
          list_packge_addon: this.listpackage_addon_,
          type_invoice:"Y"
        };
        // console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/payment_package_storage", payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            // console.log("res*", response.data.result);
            if (response.data.status === "OK") {
              // console.log("response", response);
              this.URL_payment = response.data.result;
              this.gotopay();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      }
    },
    gotopay() {
      // window.open(this.URL_payment, "_blank");
      window.open(this.URL_payment, "_self");
    },
    fn_checkpackage() {
      this.listpackage_current = [];
      this.listpackage_total = [];
      this.listpackage_current_main = [];
      this.listpackage_current_addon = [];

      let payload = {
        // data_id: this.dataAccountId,
        data_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
        type: this.dataAccountActive.type === 'Citizen' ? 'citizen': 'business',
      };
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/get_package_current",
          payload
          //  {headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            // console.log("response#######", response);
            for (var i = 0; i < response.data.details_package.length; i++) {
              let datapackage = {};
              datapackage["number_user"] = response.data.details_package[i].number_user;
              datapackage["package_id"] = response.data.details_package[i].package_id;
              datapackage["package_name"] = response.data.details_package[i].package_name;
              datapackage["package_name_th"] = response.data.details_package[i].package_name_th;
              datapackage["package_price"] = response.data.details_package[i].package_price;
              datapackage["package_storage"] = response.data.details_package[i].package_storage;
              datapackage["period"] = response.data.details_package[i].period;
              datapackage["status_payment"] = response.data.details_package[i].status_payment;
              datapackage["type_package"] = response.data.details_package[i].type_package;
              datapackage["type_period"] = response.data.details_package[i].type_period;
              datapackage["quantity"] = response.data.details_package[i].quantity;

              this.listpackage_current.push(datapackage);

              if (response.data.details_package[i].type_package === "main") {
                this.listpackage_current_main.push(datapackage);
              } else {
                this.listpackage_current_addon.push(datapackage);
              }
            }
            // console.log("this.listpackage_current+++++", this.listpackage_current);

            // ******* package main *******

            // console.log(this.listpackage_addon);
            this.list_selected_package_main = [];
            this.list_selected_package_main_show = [];
            // for (let i = 0; i < this.listpackage.length; i++) {
            // this.dataaddon_Index = this.listpackage_current_main.findIndex((obj) => obj.package_id === this.listpackage[i].id);
            // console.log("this.dataaddon_Index-----", this.dataaddon_Index);
            if (this.listpackage_current_main.length != 0) {
              let objIndex = this.listpackage.findIndex((obj) => obj.id === this.listpackage_current_main[0].package_id);
              console.log("objIndex", objIndex);
              this.model = objIndex;
              if (objIndex !== -1) {
                this.activeIndex = this.listpackage[objIndex].id;
              } else {
                this.activeIndex = -1;
              }
              
              // this.list_selected_package_main.push({ package_id: this.listpackage[objIndex].id, quantity: 1 });
              // แก้ใหม่ล่าสุด 24/03/65 ///////ปิดการโชว์จำนวนpackage main ที่เคยซื้อไว้ //////
              // this.list_selected_package_main_show.push({
              //   package_id: this.listpackage[objIndex].id,
              //   namepackage_th: this.listpackage[objIndex].package_name_th,
              //   namepackage: this.listpackage[objIndex].package_name,
              //   storage: this.listpackage[objIndex].package_storage,
              //   price: this.listpackage[objIndex].package_price,
              //   user: this.listpackage[objIndex].package_number_user,
              //   quantity: 1,
              // });
              // this.list_selected_package_main_update[objIndex].quantity = 1;
              // console.log("======0000======",this.list_selected_package_main_show);
              // console.log("1111111", this.list_selected_package_main_update);
            }
            // }

            // ******* package addon *******
            // console.log("this.listpackage_current_addon", this.listpackage_current_addon);
            for (let j = 0; j < this.listpackage_current_addon.length; j++) {
              // console.log("jjj", this.listpackage_current_addon[j]);
              this.dataaddon_Index_addon = this.listpackage_addon.findIndex(
                (obj) => obj.id === this.listpackage_current_addon[j].package_id
              );
              // console.log(":::::::::",this.dataaddon_Index_addon);
              //วนโชว์จำนวนแพ็กเกจเสริม
              if (this.dataaddon_Index_addon !== -1) {
                // console.log(">>>>>>",this.listpackage_current_addon[this.dataaddon_Index_addon]);
                // แก้ใหม่ล่าสุด 25/03/65/////โชว์จำนวนเมนู addon ที่เคยซื้อไว้ //////
                // this.listpackage_addon[this.dataaddon_Index_addon].package_num_package += 1; 
                this.listpackage_addon[this.dataaddon_Index_addon].quantity = this.listpackage_addon[
                  this.dataaddon_Index_addon
                ].package_num_package;
                this.listpackage_addon[this.dataaddon_Index_addon].package_price_sum =
                  this.listpackage_addon[this.dataaddon_Index_addon].package_price *
                  this.listpackage_addon[this.dataaddon_Index_addon].package_num_package;

                //ส่งไปโชว์ dialog update package
                // this.listpackage_addon_show_Update[this.dataaddon_Index_addon].package_num_package += 1;
                this.listpackage_addon_show_Update[this.dataaddon_Index_addon].quantity = this.listpackage_addon_show_Update[
                  this.dataaddon_Index_addon
                ].package_num_package;
                this.listpackage_addon_show_Update[this.dataaddon_Index_addon].package_price_sum =
                  this.listpackage_addon_show_Update[this.dataaddon_Index_addon].package_price *
                  this.listpackage_addon_show_Update[this.dataaddon_Index_addon].package_num_package;

                // console.log("this.listpackage_addon_show_Update",this.listpackage_addon_show_Update);

                // console.log('quantity',this.listpackage_addon_[this.dataaddon_Index_addon].quantity);
                // console.log(typeof this.listpackage_addon[this.dataaddon_Index_addon].package_price);
                // console.log(typeof this.listpackage_addon[this.dataaddon_Index_addon].package_num_package);
              }
            }

            for (let i = 0; i < this.listpackage_current_addon.length; i++) {
              // console.log("iii", this.listpackage_addon_[i]);
              this.dataaddon_Index_addon_ = this.listpackage_addon_.findIndex(
                (obj) => obj.package_id === this.listpackage_current_addon[i].package_id
              );
              // console.log(" this.dataaddon_Index_addon_ [][][][][][]",this.dataaddon_Index_addon_);
              //แก้ไข 25/03/65 ไม่นับจำนวนแพ็กเกจอันเก่าที่เคยซื้อไป
              // this.listpackage_addon_[this.dataaddon_Index_addon_].quantity += 1;
            }
            this.pricetotal_addon = this.listpackage_addon.reduce((a, b) => a + b.package_price_sum, 0);
            // console.log("+++++",this.pricetotal_addon );
            // console.log("this.list_selected_package_main_show[0].price",this.list_selected_package_main_show[0].price);
            //ปิดคำนวนราคารวม
            // this.vat_package(this.list_selected_package_main_show[0].price , this.pricetotal_addon);
            // console.log("//",this.list_selected_package_main_show);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    sum() {
      // console.log(this.listpackage_addon);
      this.pricetotal_addon = this.listpackage_addon.reduce(
        (a, b) => console.log("a", a) + console.log("b", b.package_price_sum),
        0
      );
      this.vat_package(this.list_selected_package_main_show[0].price, this.pricetotal_addon);
    },
    fn_getstorage_user() {
      let payload = {
        account_id: this.dataAccountId,
      };
      // console.log("payload fn_getstorage_user", payload);
      this.axios.post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/get_conclusion_package", payload).then((response) => {
        if (response.data.status === "OK") {
          //storage
          this.total_storage_business = response.data.result.storage_business;
          this.use_storage_business = response.data.result.used_storage_business;
          // console.log("this.total_storage_business",this.total_storage_business);
          // console.log("this.use_storage_business",this.use_storage_business);

          //user
          this.total_user = response.data.result.number_of_users;
          this.use_user = response.data.result.used_users;
          // console.log("this.total_user",this.total_user);
          // console.log("this.use_user ",this.use_user );

          // คำนวณ % user
          let percentUsageUser = (this.use_user / this.total_user) * 100;
          this.percentuser = percentUsageUser === Infinity || percentUsageUser > 100 ? 100 : Math.ceil(percentUsageUser);
          // console.log("percentUsageUser", percentUsageUser);
          // console.log("this.percentuser",this.percentuser);

          //คำนวน % storage
          // let datastorage_total = this.total_storage_business.split(" ");
          // let datastorage_use = this.use_storage_business.split(" ");
          let percentUsageStorage =
            (this.convertstorageToByte(this.use_storage_business) / this.convertstorageToByte(this.total_storage_business)) * 100;
          this.percentstorage =
            percentUsageStorage === Infinity || percentUsageStorage > 100 ? 100 : Math.ceil(percentUsageStorage);
          // console.log("percentUsageStorage", percentUsageStorage);
          // console.log("this.percentstorage", this.percentstorage);

          // setสี
          this.color_user = this.percentageuser_setcolor(this.percentuser);
          this.color_storage = this.percentagestoage_setcolor(this.percentstorage);
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage,
          });
        }
      });
    },
    percentageuser_setcolor() {
      if (this.percentuser >= 0 && this.percentuser < 51) {
        return "#5AB685";
      } else if (this.percentuser >= 51 && this.percentuser < 81) {
        return "#E9A016";
      } else if (this.percentuser >= 81) {
        return "#D1392B";
      }
    },
    percentagestoage_setcolor() {
      if (this.percentstorage >= 0 && this.percentstorage < 51) {
        return "green";
      } else if (this.percentstorage >= 51 && this.percentstorage < 81) {
        return "#ffcc00";
      } else if (this.percentstorage >= 81) {
        return "red";
      }
    },
    fn_plus_package(index, value, i) {
      // console.log("บวก");
      // console.log("i", i);
      // console.log("num_package", value);
      // console.log("index", index);
      // console.log("value && value.package_num_package", value.package_num_package);
      // this.pricetotal_addon = 0;
      if (value && value.package_num_package) {
        this.listpackage_addon[index].quantity = value.package_num_package;
        this.listpackage_addon_[index].quantity = value.package_num_package;
      }

      //ราคาแพ็กเกจ
      this.listpackage_addon[index].package_price_sum = this.listpackage_addon[index].package_price * value.package_num_package;
      this.listpackage_addon_[index].package_price_sum = this.listpackage_addon[index].package_price * value.package_num_package;
      console.log(this.listpackage_addon);
      console.log(this.listpackage_addon_);

      //ราคารวม
      this.pricetotal_addon = this.listpackage_addon.reduce((a, b) => a + b.package_price_sum, 0);
      console.log("this.pricetotal_addon price",this.pricetotal_addon);
      console.log("22",this.list_selected_package_main_show);
      this.vat_package(this.list_selected_package_main_show.length == 0 ? 0 : this.list_selected_package_main_show[0].price, this.pricetotal_addon);
      // console.log(this.pricetotal_addon);
    },
    fn_minus_package(index, value, i) {
      // console.log("ลบ ++");
      // console.log("i", i);
      // console.log("num_package", value);
      // console.log("index", index);
      // console.log("value && value.package_num_package", value.package_num_package);
      // this.pricetotal_addon = 0;
      // if (value && value.package_num_package) {
      this.listpackage_addon[index].quantity = value.package_num_package;
      this.listpackage_addon_[index].quantity = value.package_num_package;
      // }

      //ราคารวมแพ็กเกจ
      this.listpackage_addon[index].package_price_sum = this.listpackage_addon[index].package_price * value.package_num_package;
      // console.log("value.package_num_package", value.package_num_package);
      // console.log("sum---", this.listpackage_addon[index]);
      this.listpackage_addon_[index].package_price_sum = this.listpackage_addon[index].package_price * value.package_num_package;
      // console.log(this.listpackage_addon);
      // console.log(this.listpackage_addon_);

      //ราคารวมทั้งหมด
      this.pricetotal_addon = this.listpackage_addon.reduce((a, b) => a + b.package_price_sum, 0);
      this.vat_package(this.list_selected_package_main_show.length == 0 ? 0 : this.list_selected_package_main_show[0].price, this.pricetotal_addon);
      // console.log(this.pricetotal_addon);
    },
    fn_goto_packagehistory() {
      this.$router.replace({ path: "/packagehistory" });
    },
    fn_goto_mypackage() {
      this.$router.replace({ path: "/mypackage" });
    },
    fn_selectpackage(active, item, value) {
      // console.log("active", active);
      //อันเก่า เคลียร์ให้แพ็กเกจเก่าเป็น quantity = 0
      for (let i = 0; i < this.list_selected_package_main_update.length; i++) {
        this.list_selected_package_main_update[i].quantity = 0;
      }
      console.log(active);
      if (active === false) {
        this.activeIndex = item.id
        // console.log("item", item);
        // this.setcolor = true;
      
        this.list_selected_package_main = [];
        this.list_selected_package_main_show = [];

        // console.log("item", item);
        // console.log("value", value);
        this.list_selected_package_main.push({ package_id: item.id, quantity: value });
        this.list_selected_package_main_show.push({
          package_id: item.id,
          namepackage: item.package_name,
          storage: item.package_storage,
          price: item.package_price,
          user: item.package_number_user,
          quantity: value,
        });
        // console.log(this.listpackage_current_main[0].package_id);
        let objIndex = this.list_selected_package_main_update.findIndex(
          (obj) => obj.package_id === this.list_selected_package_main[0].package_id
        );
        // console.log("objIndex=========", objIndex);
        this.list_selected_package_main_update[objIndex].quantity = 1;
     
        // console.log("list_selected_package_main_update", this.list_selected_package_main_update);
        
        // //เคลียร์แพ็กเกจเสริม
        // for (let i = 0; i < this.listpackage_addon.length; i++) {
        //   this.listpackage_addon[i].quantity = 0;
        //   this.listpackage_addon[i].package_num_package = 0;
        //   this.listpackage_addon[i].package_price_sum = 0;
        // }
        // for (let i = 0; i < this.listpackage_addon_.length; i++) {
        //   this.listpackage_addon_[i].quantity = 0;
        //   this.listpackage_addon_[i].package_num_package = 0;
        //   // this.listpackage_addon[i].package_price_sum = 0;
        // }
        // this.pricetotal_addon = 0;        
        this.pricetotal_addon = this.listpackage_addon.reduce((a, b) => a + b.package_price_sum, 0);
        this.vat_package(this.list_selected_package_main_show[0].price, this.pricetotal_addon);
      } else {
        this.activeIndex = -1
        this.vat = 0;
        this.pricetotal = 0;
        this.list_selected_package_main = [];
        this.list_selected_package_main_show = [];

        for (let i = 0; i < this.listpackage_addon.length; i++) {
          this.listpackage_addon[i].quantity = 0;
          this.listpackage_addon[i].package_num_package = 0;
          this.listpackage_addon[i].package_price_sum = 0;
        }
        for (let i = 0; i < this.listpackage_addon_.length; i++) {
          this.listpackage_addon_[i].quantity = 0;
          // this.listpackage_addon_[i].package_num_package = 0;
        }

        // console.log("this.listpackage_addon", this.listpackage_addon);
        // console.log("this.listpackage_addon_", this.listpackage_addon_);
        // console.log("list_selected_package_main_update", this.list_selected_package_main_update);
      }
    },
    vat_package(mainpack, addonpack) {
      console.log("-mainpack", mainpack);
      console.log("-addonpack", addonpack);
      if (mainpack === "" || mainpack === undefined || mainpack === null) {
        this.vat = 0;
      } else {
        // let pricevat = (mainpack + addonpack) * 0.07
        this.vat = ((parseFloat(mainpack) + addonpack)* 0.07).toFixed(2);
        console.log((parseFloat(mainpack) + addonpack) * 0.07);
        console.log("this.vat",this.vat);
      }
      console.log("vat", this.vat);
      this.fn_price_total(this.list_selected_package_main_show.length == 0 ? 0 : this.list_selected_package_main_show[0].price, this.pricetotal_addon, this.vat);
    },
    fn_price_total(main, addon, vat) {
      console.log("main",typeof main);
      console.log('vat',typeof vat);
      console.log('addon',typeof addon);
      this.pricetotal = (parseInt(main) + addon + parseFloat(vat)).toFixed(2);
      console.log("this.pricetota",this.pricetota);
    },
    fn_tax_invoice() {
      this.checktaxbtn = 2;
      this.opentaxinvoice = true;
    },
    fn_tax_invoice_bybtnpay() {
      this.checktaxbtn = 1;
      this.opentaxinvoice = true;
    },
    // fn_get_tax_invoice () {
    //   let payload = {
    //     business_id: this.dataAccountActive.business_info.business_id
    //   };
    //   // console.log("payload", payload);
    //   this.axios
    //     .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_taxinvoic_address", payload)
    //     .then((response) => {
    //       if (response.data.status === "OK") {
    //         // console.log('fn_get_tax_invoice', response.data.result)
        
    //         this.tax_num = response.data.result.taxid;
    //         this.noaddress = response.data.result.HouseNumber;
    //         this.building = response.data.result.BuildingName;
    //         this.floor = response.data.result.FloorNumber;
    //         this.road = response.data.result.StreetName;
    //         this.subdistrict = response.data.result.Thambol;
    //         this.district = response.data.result.Amphur;
    //         this.province = response.data.result.Province;
    //         this.postcode = response.data.result.PostCode;
    //         this.typebusiness = response.data.result.business_TitleName_th;
    //         this.name_business_th = response.data.result.business_name;
    //         this.typebusiness_eng = response.data.result.business_TitleName_en;
    //         this.radios = response.data.result.BranchNumber === "00000" ? "สำนักงานใหญ่" : "สาขาอื่นๆ";
    //         this.branch_no = response.data.result.BranchNumber;
    //         this.branch_name = response.data.result.BranchNumber === "00000" ? "สำนักงานใหญ่" : "สาขาอื่นๆ";
    //         this.email_business = response.data.result.email
    //         this.tel_biz = response.data.result.phone_numbers
    //         this.mooban = response.data.result.MooName
    //         this.no_moo = response.data.result.MooNumber
    //         this.noroom = response.data.result.RoomNumber
    //         this.yaek = response.data.result.Separate
    //         this.soi = response.data.result.SoiName
    //         this.fax = response.data.result.fax_number
    //         this.name_invoice_th = response.data.result.full_business_name
    //         this.phone_number_biz= response.data.result.telephone_number
    //         this.loaddataprogress = false;
    //       } else {
    //         this.loaddataprogress = false;
    //         // Toast.fire({
    //         //   icon: "error",
    //         //   title: response.data.errorMessage,
    //         // });
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.loaddataprogress = false;
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
    //       });
    //     });
    // },
    fn_check_tax_invoice() {
      // console.log("fn_check_tax_invoice");
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
      };
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_taxinvoic_address", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            this.taxInvoice = true;
            this.address = response.data.address
            this.tax_num = response.data.result.taxid;
            this.noaddress = response.data.result.HouseNumber;
            this.building = response.data.result.BuildingName;
            this.floor = response.data.result.FloorNumber;
            this.road = response.data.result.StreetName;
            this.subdistrict = response.data.result.Thambol;
            this.district = response.data.result.Amphur;
            this.province = response.data.result.Province;
            this.postcode = response.data.result.PostCode;
            this.typebusiness = response.data.result.business_TitleName_th;
            this.name_business_th = response.data.result.business_name;
            this.typebusiness_eng = response.data.result.business_TitleName_en;
            this.radios = response.data.result.BranchNumber === "00000" ? "สำนักงานใหญ่" : "สาขาอื่นๆ";
            this.branch_no = response.data.result.BranchNumber;
            this.branch_name = response.data.result.branchname;
            this.email_business = response.data.result.email
            this.tel_biz = response.data.result.phone_numbers
            this.mooban = response.data.result.MooName
            this.no_moo = response.data.result.MooNumber
            this.noroom = response.data.result.RoomNumber
            this.yaek = response.data.result.Separate
            this.soi = response.data.result.SoiName
            this.fax = response.data.result.fax_number
            this.name_invoice_th = response.data.result.full_business_name
            this.phone_number_biz= response.data.result.telephone_number
          } else {
            this.taxInvoice = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    fn_clear() {
      if(this.listpackage_current_main.length == 0){
        this.model= null;
      }
      this.list_selected_package_main = [];
      this.list_selected_package_main_show = [];

      for (let i = 0; i < this.listpackage_addon.length; i++) {
        this.listpackage_addon[i].quantity = 0;
        this.listpackage_addon[i].package_num_package = 0;
      }
      for (let i = 0; i < this.listpackage_addon_.length; i++) {
        this.listpackage_addon_[i].quantity = 0;
        this.listpackage_addon_[i].package_num_package = 0;
      }
      this.pricetotal_addon = 0;
      this.vat = 0;
      this.pricetotal = 0;
    },
    fn_reload() {
      this.getdatapackage(this.packageTypeSelected);
      this.fn_getstorage_user();
      this.fn_check_tax_invoice();
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  mounted() {
    // console.log("dataStorageMax", this.dataStorageMax);
    // console.log("storage_usage", this.storage_usage);
    this.getdatapackage(this.packageTypeSelected);
    this.fn_getstorage_user();
    //  this.percentageStorage();
    this.fn_check_tax_invoice();
    // if(this.taxInvoice === true){
      // this.fn_get_tax_invoice();
    // }
    // this.fn_checkpackage();
  },
};
</script>
<style scoped>
#thiscontainer_package {
  height: calc(101vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
#borderradius {
  border-radius: 15px;
}
#borderradius_card {
  border-radius: 26px;
}
.primary {
  background-color: #1279bc !important;
  border-color: #1279bc !important;
}
</style>
